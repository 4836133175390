// import React, { useContext, useEffect, useState } from 'react';
// import Loader from '../Loader/Loader';
// import { GlobalContext } from '../../context/GlobalContext';
// import { formatText } from '../../utils/formatText';
// import IconNKOO from '../../img/nkoo-icon.png';
// import '../../css/ChatScreen.css';

// const ChatScreen = () => {
//   const { messages, isLoading } = useContext(GlobalContext);
//   const [displayMessages, setDisplayMessages] = useState([]);
//   const typingSpeed = 50;

//   // Effect om nieuwe berichten toe te voegen
//   useEffect(() => {
//     const newMessages = messages.slice(displayMessages.length);
//     setDisplayMessages(prevMessages => [...newMessages, ...prevMessages]);

//     const typeTextInChunks = (message, index) => {
//       let fullText = '';
//       let currentIndex = 0;
//       const words = message.text.split(' ');

//       const step = () => {
//         if (currentIndex < words.length) {
//           fullText += (currentIndex === 0 ? '' : ' ') + words[currentIndex];
//           setDisplayMessages(prevMessages => {
//             const updatedMessages = [...prevMessages];
//             updatedMessages[index] = { ...updatedMessages[index], text: formatText(fullText) };
//             return updatedMessages;
//           });
//           currentIndex += 1;
//           setTimeout(() => {
//             requestAnimationFrame(step);
//           }, typingSpeed);
//         }
//       };

//       requestAnimationFrame(step);
//     };

//     newMessages.forEach((msg, idx) => {
//       if (msg.sender === 'bot') {
//         typeTextInChunks(msg, newMessages.length - 1 - idx); // Omgekeerde volgorde
//       }
//     });
//   }, [messages, displayMessages.length]);

//   return (
//     <div className='inner'>
//       <div className="chat-screen">
//         {isLoading && <Loader />}
//         {displayMessages.map((msg, index) => (
//           <div key={index} className='chat-message'>
//             <div className={`message ${msg.sender}`}>
//               {msg.text}
//             </div>
//             <div className={`chatid ${msg.sender}`}>
//               {msg.sender === 'bot' && <img alt='chatbot icon' src={IconNKOO} />}
//               <p>{msg.sender === 'bot' ? 'NKOO' : 'Jij'}</p>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default ChatScreen;

import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import Loader from '../Loader/Loader';
import { GlobalContext } from '../../context/GlobalContext';
import { formatText } from '../../utils/formatText';
import IconNKOO from '../../img/nkoo-icon.png'
import '../../css/ChatScreen.css';

const ChatScreen = () => {
  const { messages, isLoading } = useContext(GlobalContext);
  const [displayMessages, setDisplayMessages] = useState([]);
  const typingSpeed = 50;
  const chatEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [isUserScrolling, setIsUserScrolling] = useState(false);

  const scrollToBottom = useCallback(() => {
    if (!isUserScrolling) {
      chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isUserScrolling]);

  useEffect(() => {
    scrollToBottom();
  }, [displayMessages, scrollToBottom]);

  useEffect(() => {
    const newMessages = messages.slice(displayMessages.length);
    setDisplayMessages(prevMessages => [...newMessages, ...prevMessages]);

    const typeTextInChunks = (message, index) => {
      let fullText = '';
      let currentIndex = 0;
      const words = message.text.split(' ');

      const step = () => {
        if (currentIndex < words.length) {
          fullText += (currentIndex === 0 ? '' : ' ') + words[currentIndex];
          setDisplayMessages(prevMessages => {
            const updatedMessages = [...prevMessages];
            updatedMessages[index] = { ...updatedMessages[index], text: formatText(fullText) };
            return updatedMessages;
          });
          currentIndex += 1;
          setTimeout(() => {
            requestAnimationFrame(step);
          }, typingSpeed);
        }
      };

      requestAnimationFrame(step);
    };

    newMessages.forEach((msg, idx) => {
      if (msg.sender === 'bot') {
        typeTextInChunks(msg, newMessages.length - 1 - idx); // Omgekeerde volgorde
      }
    });
  }, [messages, displayMessages.length, scrollToBottom]);

  const handleScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setIsUserScrolling(false);
      } else {
        setIsUserScrolling(true);
      }
    }
  };

  return (
    <div className='inner'>
      <h2>With scroll</h2>
    <div className="chat-screen" ref={chatContainerRef} onScroll={handleScroll}>
      {isLoading && <Loader />}
      {displayMessages.map((msg, index) => (
        <div key={index} className='chat-message'>
        <div className={`message ${msg.sender}`}>
          {msg.text}
        </div>
        <div className={`chatid ${msg.sender}`}>
          {msg.sender === 'bot' && <img alt='chatbot icon' src={IconNKOO} />}
        <p>{msg.sender === 'bot' ? 'NKOO' : 'Jij'}</p>
        </div>
        </div>
      ))}
      <div ref={chatEndRef} />
    </div></div>
  );
};

export default ChatScreen;
