import React, { useContext, useState } from 'react';
import { GlobalContext } from '../../context/GlobalContext';
import { sendMessageToBackend } from '../../api/ChatAPI';
import '../../css/UserInput.css'
import SendIcon from '../../img/send.svg';

const UserInput = () => {
  const [userInput, setUserInput] = useState('');
  const { addMessage, setIsLoading } = useContext(GlobalContext);

  const handleInputChange = (event) => {
    setUserInput(event.target.value);
  };

  const sendPrompt = async (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSend();
    }
  };

  const handleSend = async () => {
    if (userInput.trim()) {
      setIsLoading(true);
      setUserInput('');
      try {
        // Voeg gebruikersbericht toe aan de context
        addMessage({ sender: 'user', text: userInput });

        // Verstuur bericht naar backend en ontvang antwoorden
        const response = await sendMessageToBackend(userInput);

        // Voeg AI-bericht toe aan de context als een string
        addMessage({ sender: 'bot', text: response });

        
      } catch (error) {
        console.error('Failed to send message:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="user-input">
      <div className='input-group'>
      <input
        type="text"
        value={userInput}
        onChange={handleInputChange}
        onKeyDown={sendPrompt}
        placeholder="Typ je bericht..."
      />
      <button onClick={handleSend}><img alt='sendbtn' src={SendIcon} /></button>
    </div>
    </div>
  );
};

export default UserInput;
