import React from 'react';

export const formatText = text => {
  const regex = /(\*\*(.*?)\*\*|### (.*?)(?=\n|$)|# (.*?)(?=\n|$)|\n|\[(.*?)\]\((.*?)\)|((http|https):\/\/[^\s\]]+))/g;
  let match;
  const elements = [];
  let lastIndex = 0;

  while ((match = regex.exec(text)) !== null) {
    if (match.index > lastIndex) {
      elements.push(text.substring(lastIndex, match.index));
    }

    if (match[0].startsWith("**")) {
      elements.push(<strong key={match.index}>{match[2]}</strong>);
    } else if (match[0].startsWith("###")) {
      const headingContent = match[3];
      const nestedElements = formatText(headingContent);
      elements.push(<h2 key={match.index}>{nestedElements}</h2>);
    } else if (match[0].startsWith("#")) {
      const headingContent = match[4];
      const nestedElements = formatText(headingContent);
      elements.push(<h4 key={match.index}>{nestedElements}</h4>);
    } else if (match[0] === "\n") {
      elements.push(<br key={match.index} />);
    } else if (match[5] && match[6]) {
      elements.push(
        <a
          href={match[6]}
          key={match.index}
          target="_blank"
          rel="noopener noreferrer"
        >
          {match[5]}
        </a>
      );
    } else if (match[7]) {
      elements.push(
        <a
          href={match[7]}
          key={match.index}
          target="_blank"
          rel="noopener noreferrer"
        >
          {match[7]}
        </a>
      );
    }

    lastIndex = match.index + match[0].length;
  }

  if (lastIndex < text.length) {
    elements.push(text.substring(lastIndex));
  }

  return elements;
};