import React from 'react';
import UserInput from './components/UserInput/UserInput';
import ChatScreen from './components/ChatScreen/ChatScreen';
import { GlobalProvider } from './context/GlobalContext';
import './App.css';


function App() {
  return (
    <GlobalProvider>
      <div className="app">
        <div className='test'>
        <ChatScreen />
        <UserInput />
        </div>
      </div>
    </GlobalProvider>
  );
}

export default App;
