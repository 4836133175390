import axios from 'axios';

const API_URL = 'https://kj6mj288ll.execute-api.eu-west-3.amazonaws.com/bot';

const settings = {
  selectStroom: "Wel",
  selectEhbwoo: "Wel",
  selectPraktijkboek: "Wel",
  selectWet: "Wel",
  selectBlogs: "Wel",
  selectNkoo: "Wel",
  selectStroomK: 2,
  selectEhbwooK: 2,
  selectPraktijkboekK: 2,
  selectWetK: 2,
  selectBlogsK: 2,
  selectNkooK: 2
};

export const sendMessageToBackend = async (userInput) => {
  try {
    console.log("Sending request to API...");
    console.log("userInput:", { chat_input: userInput });

    const response = await axios.post(
      API_URL,
      {
        chat_input: userInput,
        stroom: settings.selectStroom,
        nkoo: settings.selectNkoo,
        wet: settings.selectWet,
        blogs: settings.selectBlogs,
        praktijk: settings.selectPraktijkboek,
        ehbwoo: settings.selectEhbwoo,
        stroom_k: settings.selectStroomK,
        nkoo_k: settings.selectNkooK,
        wet_k: settings.selectWetK,
        blogs_k: settings.selectBlogsK,
        praktijk_k: settings.selectPraktijkboekK,
        ehbwoo_k: settings.selectEhbwooK
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Accept": "text/event-stream, application/json"
        }
      }
    );

    console.log("Response", response.data);

    let fullResponse = response.data.chat_output;

    console.log("Full response received:", fullResponse);
    return fullResponse.trim();

  } catch (error) {
    console.error("Error sending message to backend:", error);
    return 'Sorry, het lukt helaas niet om je vraag goed te verwerken. Probeer het opnieuw of stel een andere vraag.';
  }
};
