import {createContext, useState} from 'react'

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    
    const addMessage = (message) => {
      setMessages(prevMessages => [...prevMessages, message]);
    };
  
    const contextValue = {
      messages,
      setMessages,
      isLoading,
      setIsLoading,
      addMessage,
    };
  
    return (
      <GlobalContext.Provider value={contextValue}>
        {children}
      </GlobalContext.Provider>
    );
  };
